<template>
  <div class="title" :class="className">
    <router-link :to="{ name: 'Events' }">
      <Icon class="icon-Arrow-left" />
    </router-link>
    {{ label }}
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Title",
  components: { Icon },
  props: {
    icon: Boolean,
    label: String,
    border: Boolean,
  },
  computed: {
    className() {
      return {
        "title--icon": this.icon,
        "title--border": this.border,
      };
    },
  },
};
</script>

<style lang="scss">
.title {
  display: flex;
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 500;
  margin-bottom: 20px;
  @include adaptive(phone) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 15px;
  }

  & a {
    display: none;
    & i {
      position: relative;
      font-size: 2.4rem;
      font-weight: 500;
      color: var(--text--color);
      @include adaptive(phone) {
        font-size: 1.4rem;
      }
    }
  }
  &--icon {
    gap: 10px;
    @include adaptive(phone) {
      gap: 5px;
    }
    & a {
      display: flex;
      align-items: center;
    }
  }
  &--border {
    border-bottom: 1px solid var(--background--btn--profile--hover);
    padding-bottom: 20px;
    @include adaptive(tablet-big) {
      padding-bottom: 15px;
    }
  }
}
</style>
